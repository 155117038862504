import { ActionIcon, Avatar, Flex, Grid, Group, LoadingOverlay, Pagination, Paper, Select, Space, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronsUpRight, IconPackage, IconPhoto, IconStar } from "@tabler/icons-react";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";

export * from "./RowAction";

export type ColumnType<T> = {
  title: string;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  filters?: { [key: string]: string | number | any };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  onClick: any;
};

export type ITableRef = { reload: () => void };

export const SuppTable = React.forwardRef(({ name, filters = {}, pagination = true, dataSource = [], loadData, onClick }: Props, ref: React.Ref<ITableRef>) => {
  const { classes } = useStyles();
  const [offset, setOffset] = React.useState({ page: 1, limit: 10 });
  const { user } = useSelector((state: { auth: any }) => state.auth);

  const { data, mutate, isLoading } = useSwr(
    `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
    async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
    {
      fallbackData: { count: dataSource.length, rows: dataSource },
    },
  );

  useImperativeHandle(ref, () => ({
    reload() {
      return mutate();
    },
  }));

  function PartnerCard({ item, filter }: any) {
    return (
      <Paper withBorder radius="lg" p="md">
        <Flex align="start" justify="space-between" h={"100%"}>
          <Group align="start">
            <Paper withBorder radius="xl">
              <Avatar src={item?.logo ?? item?.image} radius="xl">
                <IconPhoto />
              </Avatar>
            </Paper>

            <div>
              <Text fz="lg" fw={600}>
                {item?.profileName}
              </Text>
              <Text fz="xs" tt="uppercase" fw={500} c="dimmed">
                {item?.partner?.businessName}
              </Text>

              <Group mt={3} align="center">
                <IconStar stroke={1.5} size="1rem" className={classes.icon} />
                <IconStar stroke={1.5} size="1rem" className={classes.icon} />
                <IconStar stroke={1.5} size="1rem" className={classes.icon} />
                <IconStar stroke={1.5} size="1rem" className={classes.icon} />
                <Text size="sm" c="dimmed">
                  (11248)
                </Text>
              </Group>
            </div>
          </Group>
          <ActionIcon onClick={() => onClick(item, filter)} m="6px" variant="light" color={""}>
            <IconChevronsUpRight />
          </ActionIcon>
        </Flex>
      </Paper>
    );
  }

  return (
    <div className={classes.container}>
      <Grid>
        {data?.rows
          ?.filter((item: any) => user?.partner?.id !== item.partner.id)
          .map((item: any, index: number) => {
            return (
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} key={item.id ?? index}>
                <PartnerCard item={item} filter={filters} />
              </Grid.Col>
            );
          })}

        {data?.rows?.length === 0 && (
          <div className={classes.notFound}>
            <Flex direction="column" align="center">
              <IconPackage size={"50px"} color="gray" stroke="1" />
              <Text c="gray" size="md">
                Өгөгдөл олдсонгүй.
              </Text>
            </Flex>
          </div>
        )}
      </Grid>
      <LoadingOverlay visible={isLoading} />

      {pagination && (
        <div className={classes.pagination}>
          <Pagination
            total={Math.ceil(data.count / offset.limit)}
            value={offset.page}
            onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
            siblings={1}
            boundaries={1}
            withControls={true}
            disabled={Math.ceil(data.count / offset.limit) <= 1}
            hideWithOnePage
          />
          <Space w="xs" />
          {data.count > 0 && data && (
            <Select
              onChange={(e: string | null) => {
                if (e !== null) {
                  setOffset((state) => ({ ...state, limit: parseInt(e, 10), page: 1 }));
                }
              }}
              value={`${offset.limit}`}
              w={"100px"}
              size="xs"
              defaultValue={"10"}
              data={["5", "10", "20", "30", "50", "100", "200"]}
            />
          )}
        </div>
      )}
    </div>
  );
});

const useStyles = createStyles((theme) => ({
  container: {},
  notFound: {
    width: "100%",
    height: "400px",
    padding: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.colors.gray[0],
  },
  scroll: {
    overflowX: "hidden",
    overflowY: "hidden",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
  },
  icon: {
    color: theme.colors.yellow[5],
  },

  name: {},

  card: {
    backgroundColor: theme.white,
    height: "100%",
  },

  imageSection: {
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
    maxHeight: "220px",
    minHeight: "220px",
    overflow: "hidden",
    width: "100%",
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },

  icon2: {
    marginRight: "80px",
    color: theme.colors.gray[5],
  },
}));
