import { Paper } from "@mantine/core";
import { useParams } from "react-router";
import { PageLayout } from "../../components/layout";
import { PolicyDetail } from "../../components/policy/detail";

export const DetailPolicy = () => {
  const { id } = useParams();
  const breadcrumbs = useBreadcrumb();

  return (
    <PageLayout title={"Бүтээгдэхүүний үнийн дэлгэрэнгүй"} subTitle="Үнийн дэлгэрэнгүй , засвар" breadcrumb={breadcrumbs}>
      <Paper radius="sm" p="md" withBorder>
        <PolicyDetail id={id as string} />
      </Paper>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/policy",
    label: "Стандарт үнийн бодлого",
  },
  {
    label: "Бүтээгдэхүүний үнийн дэлгэрэнгүй",
  },
];
