import { ActionIcon, Badge, Card, Flex, Grid, Group, Image, LoadingOverlay, MantineTheme, Pagination, Select, Space, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconPackage, IconPlus } from "@tabler/icons-react";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import { useLocation } from "react-router-dom";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";
import { currencyFormat } from "../../utils";

export * from "./RowAction";

export type ColumnType<T> = {
  title: string;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  filters?: { [key: string]: string | number | any };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  onClick: any;
};

export type ITableRef = { reload: () => void };

interface State {
  page: number;
  limit: number | null;
}

export const ProductTable = React.forwardRef(
  ({ name, filters = {}, pagination = true, dataSource = [], loadData, onClick }: Props, ref: React.Ref<ITableRef>) => {
    const location = useLocation();
    const { classes } = useStyles();
    const [offset, setOffset] = React.useState<State>({ page: 1, limit: 10 });
    const limit = offset.limit ?? 10;

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const isContracted = queryParams.isContracted === "true";

    const { data, mutate, isLoading } = useSwr(
      `table.${name}.[${offset.page ?? 1}, ${offset.limit ?? 10}]?${qs.stringify(filters)}`,
      async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
      {
        fallbackData: { count: dataSource.length, rows: dataSource },
      },
    );

    useImperativeHandle(ref, () => ({
      reload() {
        return mutate();
      },
    }));

    function FeaturesCard({ item, index }: any) {
      return (
        <Card radius="md" key={item.id ?? index} shadow="lg" h={"100%"} sx={(theme) => ({ border: `1px solid ${theme.colors.gray[2]}` })}>
          <Card.Section>
            <Image src={item?.image} alt={item.nameApp} className={classes.image} fit="contain" draggable={false} />
          </Card.Section>
          <Group justify="space-between" py="md">
            <Text style={{ flex: 1, paddingRight: 30 }} size="sm" lh="xs">
              {item.nameMon ?? "-"}
              {item.optionValues.length > 0
                ? item.optionValues.map((op: any) => {
                    return `, ${op.name}`;
                  })
                : ""}
            </Text>
            {item.hasUnit ? (
              <Badge color="indigo" variant="outline">
                Миний бараа
              </Badge>
            ) : (
              isContracted && (
                <ActionIcon onClick={() => onClick(index, item)} color="indigo" variant="light">
                  <IconPlus />
                </ActionIcon>
              )
            )}
          </Group>
          <Group justify="space-between" align="center" style={{ marginTop: "auto" }}>
            <Text td="line-through" c="gray" size="sm" fw={700}>
              {currencyFormat(item?.standardPrice)}
            </Text>
            <Text fz="sm" fw={700} c="green">
              {currencyFormat(item?.customPrice ?? 0)}
            </Text>
          </Group>
        </Card>
      );
    }

    return (
      <div className={classes.container}>
        <Grid gutter={20}>
          {data?.rows?.map((item: any, index: number) => {
            return (
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} key={index}>
                <FeaturesCard item={item} index={index} />
              </Grid.Col>
            );
          })}

          {data?.rows?.length === 0 && (
            <div className={classes.notFound}>
              <Flex direction="column" align="center">
                <IconPackage size={"50px"} color="gray" stroke="1" />
                <Text c="gray" size="md">
                  Өгөгдөл олдсонгүй.
                </Text>
              </Flex>
            </div>
          )}
        </Grid>
        <LoadingOverlay visible={isLoading} />

        {pagination && (
          <div className={classes.pagination}>
            <Pagination
              total={Math.ceil(data.count / limit)}
              value={offset.page}
              onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
              siblings={1}
              boundaries={1}
              withControls={true}
              disabled={Math.ceil(data.count / limit) <= 1}
              hideWithOnePage
            />
            <Space w="xs" />
            {data.count > 0 && data && (
              <Select
                onChange={(e: string | null) => {
                  if (e !== null) {
                    setOffset((state) => ({ ...state, limit: parseInt(e, 10), page: 1 }));
                  }
                }}
                value={`${limit}`}
                w={"100px"}
                size="xs"
                defaultValue={"10"}
                data={["5", "10", "20", "30", "50", "100", "200"]}
              />
            )}
          </div>
        )}
      </div>
    );
  },
);

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {},
  notFound: {
    width: "100%",
    height: "400px",
    padding: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.colors.gray[0],
  },
  scroll: {
    overflow: "hidden",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
    alignItems: "center",
    gap: 10,
  },
  icon: {
    color: theme.colors.yellow[5],
  },

  name: {},

  card: {
    padding: 0,
  },

  image: {
    height: 250,
    width: "100%",
    borderBottom: `1px solid ${theme.colors.gray[5]}`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },

  icon2: {
    marginRight: "80px",
    color: theme.colors.gray[5],
  },
}));
