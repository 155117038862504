import { Avatar, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { currencyFormat } from "../../utils";
import { ColumnType, Table } from "../table";

function DetailVariantsList({ data }: { data: any }) {
  const { tiers } = useSelector((state: { general: IGeneral }) => state.general);

  const columns = useHeader({
    tiers,
  });

  return (
    <Table
      placeholder="Та нэг ч бараа сонгож оруулаагүй байна. Үнэ өөрчлөх бараагаа сонгож оруулна уу. "
      name={`group.list.variants.${JSON.stringify(data.map((item: any) => item?.id))}`}
      columns={columns}
      pagination={false}
      dataSource={data || []}
    />
  );
}

const useHeader = ({ tiers }: { tiers: any }): ColumnType<any>[] => [
  {
    title: "Үнийн код",
    dataIndex: "refCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.refCode || "-"}
      </Text>
    ),
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record.image} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Бараа, бүтээгдэхүүний нэр",
    dataIndex: "nameMon",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.nameMon || "-"}
      </Text>
    ),
  },
  {
    title: "SKU код",
    dataIndex: "skuCode",
    render: (record) => (
      <Text size="sm" w="max-content" c="violet">
        {record?.skuCode || "-"}
      </Text>
    ),
  },
  {
    title: "Хэмжих нэгж",
    dataIndex: "unitName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unitName || "-"}
      </Text>
    ),
  },
  {
    title: "Стандарт үнэ",
    dataIndex: "standardPrice",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {currencyFormat(record?.standardPrice)}
      </Text>
    ),
  },
  {
    title: tiers?.find((item: any) => item.tierNo === 1)?.name || "Tier 1",
    dataIndex: "tier1Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 1)?.price ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 1)?.price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers?.find((item: any) => item.tierNo === 2)?.name || "Tier 2",
    dataIndex: "tier2Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 2)?.price ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 2)?.price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers?.find((item: any) => item.tierNo === 3)?.name || "Tier 3",
    dataIndex: "tier3Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 3)?.price ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 3)?.price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 4)?.name || "Tier 4",
    dataIndex: "tier4Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 4)?.price ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 4)?.price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 5)?.name || "Tier 5",
    dataIndex: "tier5Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 5)?.price ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 5)?.price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 6)?.name || "Tier 6",
    dataIndex: "tier6Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 6)?.price ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 6)?.price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 7)?.name || "Tier 7",
    dataIndex: "tier7Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 7)?.price ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 7)?.price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 8)?.name || "Tier 8",
    dataIndex: "tier8Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 8)?.price ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 8)?.price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 9)?.name || "Tier 9",
    dataIndex: "tier9Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 9)?.price ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 9)?.price || 0) : "-"}
      </Text>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 10)?.name || "Tier 10",
    dataIndex: "tier10Price",
    render: (record) => (
      <Text size="sm" ta="right" style={{ whiteSpace: "nowrap", width: "100%" }}>
        {record?.tiers?.find((item: any) => item.tierNo === 10)?.price
          ? currencyFormat(record?.tiers?.find((item: any) => item.tierNo === 10)?.price || 0)
          : "-"}
      </Text>
    ),
  },
];

export default DetailVariantsList;
