import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export function StockLineChart({ data }: { data: any[] }) {
  const getOptions = () => ({
    chart: {
      zoomType: "xy",
      type: "bar",
    },
    title: {
      text: "",
      align: "bottom",
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: data?.map((item: any) => {
          return item.name;
        }),
        crosshair: true,
      },
    ],
    yAxis: [
      {
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        title: {
          text: "",
          style: {
            color: "green",
          },
        },
      },
      {
        title: {
          text: "",
          style: {
            color: "black",
          },
        },
        labels: {
          format: "{value}",
          style: {
            color: "gray",
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "rigth",
      y: 20,
    },
    series: [
      {
        name: "Бодит үлдэгдэл",
        color: "#6043C3",
        type: "column",
        data: data?.map((item: any) => {
          return item.availableQuantity;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Заавал байх үлдэгдэл",
        type: "column",
        color: "#BEB3E6",
        data: data?.map((item: any) => {
          return item.minQuantity;
        }),
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  });

  return <HighchartsReact highcharts={Highcharts} options={getOptions()} />;
}
