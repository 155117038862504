import { Badge, Box, Divider, Grid, LoadingOverlay, Paper, Text } from "@mantine/core";
import dayjs from "dayjs";
import React from "react";
import { PriceGroupApi } from "../../apis";
import { message } from "../../utils/message";
import DetailVariantsList from "../change-standard-price/detail-variants-list";

export function PolicyDetail({ id }: { id: string }) {
  const [loading, setLoading] = React.useState<boolean>(false);

  const [data, setData] = React.useState<any>(null);

  React.useEffect(() => {
    setLoading(true);
    if (id) {
      (async () => {
        try {
          const res = await PriceGroupApi?.get(id as string);
          setData(res);
          setLoading(false);
        } catch (error: any) {
          message?.error(error?.message);
          setLoading(false);
        }
      })();
    }
  }, [id]);

  return (
    <Paper>
      {loading && <LoadingOverlay />}
      <Grid gutter={20}>
        <Grid.Col span={{ xs: 12, sm: 6, lg: 4 }}>
          <Box>
            <Text size="sm">Үнийн код</Text>
            <Text size="md">{data?.refCode}</Text>
          </Box>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, lg: 4 }}>
          <Box>
            <Text size="sm">Үнийн бодлого эхэлсэн огноо</Text>
            <Text size="md">{dayjs(data?.startDate).format("YYYY-MM-DD HH:mm")}</Text>
          </Box>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, lg: 4 }}>
          <Box>
            <Text size="sm">Үнийн бодлого дуусах огноо</Text>
            <Text size="md">{dayjs(data?.endDate).format("YYYY-MM-DD HH:mm")}</Text>
          </Box>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, lg: 4 }}>
          <Box>
            <Text size="sm">Нэр</Text>
            <Text size="md">{data?.name}</Text>
          </Box>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, lg: 4 }}>
          <Box>
            <Text size="sm">Тайлбар</Text>
            <Text size="md">{data?.description}</Text>
          </Box>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 6, lg: 4 }}>
          <Box>
            <Text size="sm">Баталгаажсан эсэх</Text>
            <Badge variant="light" color={data?.isConfirmed ? "green.5" : "red.5"} mt={5}>
              {data?.isConfirmed ? "Тийм" : "Үгүй"}
            </Badge>
          </Box>
        </Grid.Col>
      </Grid>
      <Divider my={20} />
      <Box>
        <DetailVariantsList data={data?.variants || []} />
      </Box>
    </Paper>
  );
}
