import { ActionIcon, Avatar, Box, Group, NumberInput, Text } from "@mantine/core";
import { IconPhoto, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { NumberCurrencyField } from "../form/number-currency-field";
import { ColumnType, Table } from "../table";

export function PriceChangeInfoTable({
  variants,
  setFieldValue,
  disabled = false,
  priceValue,
  changeType,
  type,
}: {
  variants: any;
  setFieldValue: any;
  disabled?: boolean;
  priceValue: number;
  changeType: string;
  type: string;
}) {
  const { tiers, currencies, variantPriceHistoryStatus } = useSelector((state: { general: IGeneral }) => state?.general);

  const columns = useHeader({
    variantPriceHistoryStatus,
    Tag,
    currencies,
    disabled,
    priceValue,
    changeType,
    type,
    onClick: (key, record) => {
      switch (key) {
        case "remove":
          setFieldValue(
            "variants",
            variants.filter((item: any) => item.id !== record.id),
          );
          break;

        default:
          break;
      }
    },
    tiers,
  });

  return <Table placeholder="Та нэг ч бараа сонгоогүй байна." name="price.change.information" columns={columns} dataSource={variants || []} />;
}

const useHeader = ({
  onClick,
  tiers,
  disabled,
  priceValue,
  changeType,
  type,
}: {
  onClick: (key: string, record: any) => void;
  disabled: boolean;
  tiers: any;
  currencies: IReference[];
  variantPriceHistoryStatus: IReference[];
  Tag: any;
  priceValue: number;
  changeType: string;
  type: string;
}): ColumnType<any>[] => [
  {
    isHide: disabled,
    title: "Үйлдэл",
    render: (record) => (
      <ActionIcon disabled={disabled} onClick={() => onClick("remove", record)} variant="light" color="">
        <IconTrash size={"1.2rem"} />
      </ActionIcon>
    ),
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record.image} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Бараа, бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "nameMon",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.nameMon ?? "-"}
      </Text>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.skuCode || "-"}
      </Text>
    ),
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.unitName ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Стандарт үнэ",
    sorter: true,
    render: (record, index) => {
      return (
        <Box>
          {!disabled ? (
            <NumberCurrencyField disabled={disabled} size="xs" name={`variants[${index}].standardPrice`} placeholder="0.00" />
          ) : (
            <Group justify={"right"}>
              <Text size="sm">{currencyFormat(record?.standardPrice || 0)}</Text>
            </Group>
          )}
        </Box>
      );
    },
  },
  {
    title: "Тусгай үнэ",
    render: (record, index) => {
      const standardPrice = record?.standardPrice || 0;
      const percentageFactor = priceValue / 100;

      let specialPrice = 0;

      if (type === "PERCENTAGE") {
        specialPrice = changeType === "INCREASE" ? standardPrice * (1 + percentageFactor) : standardPrice * (1 - percentageFactor);
      } else {
        specialPrice = changeType === "INCREASE" ? standardPrice + priceValue : standardPrice - priceValue;
      }

      return (
        <Box>
          {!disabled ? (
            <NumberInput
              disabled={disabled}
              size="xs"
              name={`variants[${index}].tier${tiers.find((item: any) => item.tierNo === 1)?.tierNo || 0}Price`}
              placeholder="0.00"
              value={specialPrice}
              hideControls
            />
          ) : (
            <Group justify={"right"}>
              <Text size="sm">{currencyFormat(specialPrice)}</Text>
            </Group>
          )}
        </Box>
      );
    },
  },
  {
    title: "Өөрчлөлт",
    render: (record) => {
      const standardPrice = record?.standardPrice || 0;

      let specialPrice = 0;

      if (type === "PERCENTAGE") {
        const percentageFactor = priceValue / 100;
        specialPrice = changeType === "INCREASE" ? standardPrice * (1 + percentageFactor) : standardPrice * (1 - percentageFactor);
      } else {
        specialPrice = changeType === "INCREASE" ? standardPrice + priceValue : standardPrice - priceValue;
      }

      const change = specialPrice - standardPrice;

      return (
        <Group justify="right">
          <Text c={change > 0 ? "green" : "red"} size="sm">
            {currencyFormat(change)}
          </Text>
        </Group>
      );
    },
  },
];
