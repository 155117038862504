import { ActionIcon, Avatar, Box, Center, CloseButton, Flex, Group, LoadingOverlay, Paper, Popover, Text, TextInput } from "@mantine/core";
import { randomId, useClickOutside, useDebouncedState } from "@mantine/hooks";
import { IconPhoto, IconPlus, IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import { PriceGroupApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { currencyFormat } from "../../utils";
import { ColumnType, Table } from "../table";

function PriceSpecialSelectList({
  setFieldValue,
  values,
  disabled = false,
}: {
  setFieldValue: (e1: string, e2: any) => void;
  values: any;
  disabled?: boolean;
}) {
  const { tiers } = useSelector((state: { general: IGeneral }) => state.general);
  const [query, setQuery] = useState<string>("");
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [queryDelay, setQueryDelay] = useDebouncedState<string>("", 800);
  const ref: any = useClickOutside<any>(() => setPopoverOpened(false));

  const {
    data: variantList = [],
    isLoading: variantIsLoading,
    mutate,
  } = useSwr<any>(
    `/api/price_group/variant/select?query=${JSON.stringify(queryDelay || null)}&${JSON.stringify({
      type: "SPECIAL",
      query: queryDelay || "",
      perValue: values.perType === "AMOUNT" ? values.perValueAmount : values.perValuePercentage,
      perType: values?.perType || null,
      changeType: values?.changeType || null,
    })}`,
    async () =>
      await PriceGroupApi.variantSelect({
        type: "SPECIAL",
        query: queryDelay,
        perValue: values.perType === "AMOUNT" ? values.perValueAmount : values.perValuePercentage,
        perType: values?.perType || null,
        changeType: values?.changeType || null,
      }),
    {
      fallback: [],
    },
  );

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "add":
          setFieldValue("variants", [
            ...(values?.variants || []),
            {
              id: record.id,
              image: record.image,
              variantId: record.id,
              skuCode: record?.skuCode,
              barCode: record?.barCode,
              erpCode: record?.erpCode,
              nameMon: `${record?.nameMon} ${record?.optionValues?.map((item: any) => (item?.name ? ", " + item?.name : ""))}`,
              nameEng: record?.nameEng,
              nameBill: record?.nameBill,
              nameWeb: record?.nameWeb,
              nameApp: record?.nameApp,
              unitId: record?.unit?.id,
              unitName: record?.unit?.name,
              standardPrice: record?.standardPrice,
              tier0Price: record?.tier0Price,
            },
          ]);
          break;

        default:
          break;
      }
    },
    tiers,
    disabled,
  });

  useEffect(() => {
    setQueryDelay(query);
  }, [query, setQueryDelay]);

  useEffect(() => {
    mutate();
  }, [mutate, queryDelay]);

  return (
    <div>
      {!disabled && (
        <Popover opened={popoverOpened} position="bottom" width="target" transitionProps={{ duration: 150, exitDuration: 150, transition: "pop" }}>
          <Popover.Target>
            <div>
              <TextInput
                leftSection={<IconSearch size={"1.2rem"} />}
                name={randomId()}
                maxLength={101}
                onFocusCapture={() => setPopoverOpened(true)}
                error={query?.length > 100 ? "Хэт урт байна." : false}
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                rightSection={
                  <CloseButton
                    onClick={() => {
                      setQuery("");
                    }}
                    size={"xs"}
                    variant="transparent"
                  />
                }
                placeholder="Хайх..."
              />
            </div>
          </Popover.Target>
          <Popover.Dropdown bg={"transparent"} p={0}>
            <Paper shadow="xl" p={"lg"} w={"50rem"} ref={ref}>
              <Box pos={"relative"}>
                <Flex align="center" mb={"xs"} w={"100%"} justify={"space-between"}>
                  <Text fw={500} fz={"xs"}>
                    Хайлтын үр дүн: ({variantList?.filter((item1: any) => !(values?.variants?.some((som: any) => som?.id === item1?.id) || false))?.length || 0}
                    )
                  </Text>
                  <CloseButton onClick={() => setPopoverOpened(false)} variant="transparent" />
                </Flex>
                <Box pos={"relative"} style={{ height: "250px", overflowY: "auto", overflowX: "hidden" }}>
                  <Table
                    minWidth={"max-content"}
                    name={`variants.list.${JSON.stringify(variantList?.map((item: any) => item?.id))}`}
                    columns={columns}
                    pagination={false}
                    dataSource={variantList?.filter((item1: any) => !values?.variants?.some((item2: any) => item2?.id === item1?.id)) || []}
                  />
                  <LoadingOverlay visible={variantIsLoading} />
                </Box>
              </Box>
            </Paper>
          </Popover.Dropdown>
        </Popover>
      )}
    </div>
  );
}

const useHeader = ({ onClick, tiers, disabled }: { onClick: (key: string, record: any) => void; tiers: any; disabled: boolean }): ColumnType<any>[] => [
  {
    title: "Нэмэх",
    render: (record) => (
      <Center>
        {!disabled && (
          <ActionIcon disabled={disabled} onClick={() => onClick("add", record)} variant="light" color="">
            <IconPlus size={"1.2rem"} />
          </ActionIcon>
        )}
      </Center>
    ),
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unitName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unit?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "unitName",
    render: (record) => (
      <Avatar src={record?.image ?? ""} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Бараа, бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "nameMon",
    render: (record) => (
      <Text size="sm" w="max-content">{`${record?.nameMon} ${record?.optionValues?.map((item: any) => (item?.name ? ", " + item?.name : ""))}`}</Text>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "description",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.skuCode || "-"}
      </Text>
    ),
  },
  {
    title: "Стандарт үнэ",
    sorter: true,
    render: (record) => (
      <Group justify="right">
        <Text size="sm">{record?.standardPrice ? currencyFormat(record?.standardPrice || 0) : "-"}</Text>
      </Group>
    ),
  },
  {
    title: tiers.find((item: any) => item.tierNo === 1)?.name || "Tier 0",
    sorter: true,
    render: (record) => (
      <Group justify="right">
        <Text size="sm">{record?.tier0Price ? currencyFormat(record?.tier0Price || 0) : "-"}</Text>
      </Group>
    ),
  },
];

export default PriceSpecialSelectList;
