import { LoadingOverlay, Paper } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { AuthApi, GeneralApi } from "./apis";
import { ChangeStandardPrice } from "./components/change-standard-price/change-standard-price";
import { UpdateStandardPrice } from "./components/change-standard-price/update-standard-price";
import { Layout } from "./components/layout";
import { usePermission } from "./components/permission";
import { IAuth } from "./interfaces/IAuth";
import { Access } from "./pages/access";
import { Dashboard } from "./pages/dashboard/dashboard";
import { NetworkPriceList } from "./pages/network/list";
import { NotFound } from "./pages/not-found";
import { EditPackage } from "./pages/package/edit";
import { NewPackage } from "./pages/package/new";
import { PriceChangesHistoryList } from "./pages/policy/PriceHistoryList";
import { DetailPolicy } from "./pages/policy/detail";
import { PolicyList } from "./pages/policy/list";
import { NewPolicy } from "./pages/policy/new";
import { EditProduct } from "./pages/product/edit";
import { Products } from "./pages/product/list";
import { NewProduct } from "./pages/product/new";
import { ReferenceList } from "./pages/reference/list";
import { ReferenceType } from "./pages/reference/type";
import { ResourceList } from "./pages/resource/list";
import { ResourceNew } from "./pages/resource/new";
import { EditService } from "./pages/service/edit";
import { NewService } from "./pages/service/new";
import { DetailsSpecialPrice } from "./pages/special-price/details";
import { EditSpecialPrice } from "./pages/special-price/edit";
import { NewSpecialPrice } from "./pages/special-price/new";
import { DetailsSupp } from "./pages/supp/deatils";
import { SuppGoods } from "./pages/supp/list";
import { Demo } from "./pages/test";
import { authMe } from "./store/auth";
import { initGeneral } from "./store/general";
import { message } from "./utils/message";

let __WINDOW_LOADED__ = false;

function App() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { accessToken, user } = useSelector((state: { auth: IAuth }) => state.auth);
  const ERP_DASH = usePermission("ERP_DASH");

  const init = React.useCallback(async () => {
    setLoading(true);
    (async () => {
      try {
        if (accessToken) {
          const res = await GeneralApi.init();
          dispatch(initGeneral(res));
          const auth = await AuthApi.me();
          dispatch(authMe(auth));
        }

        setLoading(false);
      } catch (error: any) {
        message.error(error?.message);
        setLoading(false);
      }
    })();
    setLoading(false);
  }, [accessToken, dispatch]);

  React.useEffect(() => {
    if (!__WINDOW_LOADED__) init();

    __WINDOW_LOADED__ = true;
  }, [init]);

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Routes>
      <Route element={<Layout />}>
        {user?.currentBusiness?.type === "BUYER" ? (
          <Route path="/" element={<SuppGoods />} />
        ) : (
          <Route path="/" element={ERP_DASH?.hasAccess ? <Dashboard /> : <Paper p={"md"}>{ERP_DASH.accessDenied()}</Paper>} />
        )}

        {user?.currentBusiness?.type === "SUPPLIER" && (
          <>
            <Route path="/product" element={<Products />} />
            <Route path="/product/new/" element={<NewProduct />} />
            <Route path="/product/:actionType/:id/:index" element={<EditProduct />} />
          </>
        )}

        <Route path="/policy" element={<PolicyList />} />
        <Route path="/policy/new" element={<NewPolicy />} />
        <Route path="/policy/detail/:id/:type" element={<DetailPolicy />} />
        <Route path="/policy/price/history/:id" element={<PriceChangesHistoryList />} />
        <Route path="/policy/change" element={<ChangeStandardPrice />} />
        <Route path="/policy/:id/details" element={<DetailPolicy />} />
        <Route path="/policy/:id/edit" element={<UpdateStandardPrice />} />
        <Route path="/policy/special/price/new" element={<NewSpecialPrice />} />
        <Route path="/policy/special/price/:id/details" element={<DetailsSpecialPrice />} />
        <Route path="/policy/special/price/:id" element={<EditSpecialPrice />} />

        <Route path="/reference" element={<ReferenceList />} />
        <Route path="/reference/:type" element={<ReferenceType />} />

        {user?.currentBusiness?.type === "BUYER" && (
          <>
            <Route path="/supp" element={<SuppGoods />} />
            <Route path="/supp/:id" element={<DetailsSupp />} />
          </>
        )}

        <Route path="/service/new" element={<NewService />} />
        <Route path="/service/edit/:id/:index" element={<EditService />} />
        <Route path="/customer/price" element={<NetworkPriceList />} />

        <Route path="/package-goods/new" element={<NewPackage />} />
        <Route path="/package-goods/:id" element={<EditPackage />} />

        <Route path="/resource" element={<ResourceList />} />
        <Route path="/resource/new" element={<ResourceNew />} />

        <Route path="/access" element={<Access />} />
        <Route path="/test" element={<Demo />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
